<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <div class="ontents">
      <div class="sec-title">兑换码使用记录</div>
    </div>
    <div class="search">
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="用户名">
          <el-input v-model="name"></el-input>
        </el-form-item>
        <el-form-item label="账号">
          <el-input v-model="phone"></el-input>
        </el-form-item>
        <el-form-item label="锦鲤码">
          <el-input v-model="couponCode"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div>
      <div class="contentList">
        <div class="paging">
          <el-table
            border
            ref="multipleTable"
            :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @filter-change="filterChange"
          >
            <el-table-column
              prop="course_num"
              align="center"
              width="200"
              label="发放用户"
            ></el-table-column>
            <el-table-column
              prop="course_num"
              align="center"
              width="300"
              label="账号"
            ></el-table-column>
            <el-table-column
              prop="coupon_code"
              align="center"
              width="200"
              label="锦鲤码"
            ></el-table-column>
            <el-table-column
              prop="overdue_time"
              align="center"
              label="使用的课程"
            ></el-table-column>
            <el-table-column
              prop="create_time"
              align="center"
              width="300"
              label="发放时间"
            ></el-table-column>
          </el-table>
          <!--分页-->
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="10"
            layout="prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
      <input
        id="copy_content"
        type="text"
        value=""
        style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "LiveStreaming",
  components: {},
  data() {
    return {
      nav: {
        firstNav: "优惠券管理",
        secondNav: "使用记录管理"
      },
      tableData: [], //总数据
      name: "",
      phone: "",
      couponCode: "",

      form: {
        user: "",
        date1: ""
      },
      pageSize: 10,
      currentPage: 1,
      total: 0
    };
  },
  mounted() {
    // this.GetNumbers();
    this.GetList();
  },
  methods: {
    filterChange(filterObj) {
      // console.log(filterObj, "当列数据");
    },
    GetList() {
      this.$axios
        .get(
          "/user/couponList/queryManagerListPage?status=3&currentPage=" +
            this.currentPage
        )
        .then(res => {
          this.tableData = res.data.data.rows;
          this.total = res.data.data.total;
        });
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.currentPage = val;
      this.GetList();
    },
    onSubmit() {
      if (
        this.teacher == "" &&
        this.className == "" &&
        this.startTime == "" &&
        this.endTime == ""
      ) {
        this.GetList();
      } else {
        this.$axios.get("/user/masterAppointment/queryByMessage").then(res => {
          this.tableData = res.data.data;
        });
      }
    },
    copy(row) {
      var clickContent = row.coupon_code;
      var inputElement = document.getElementById("copy_content");
      inputElement.value = clickContent;
      inputElement.select();
      document.execCommand("Copy");
    }
  }
};
</script>

<style lang="less"></style>
